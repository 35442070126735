import React from 'react'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import { StaticImage } from 'gatsby-plugin-image'

const SystemicPage = () => (
  <Layout>
    <Seo
      title="Systemische Perspektive"
      description="Die systemische Perspektive versteht den Menschen
vor dem Hintergrund seines sozialen Umfelds, seiner Familie und seiner Beziehungen."
    />
    <section className="flex flex-column flex-row-ns">
      <div className="w-100 w-50-ns">
        <StaticImage
          src={'../../images/systemisch.jpg'}
          alt="Halmafiguren"
          imgClassName="mb2 mr2"
        />
      </div>
      <div className="w-100 w-50-ns mt3 mt0-ns ml3-ns">
        <h1 id="systemisch">Systemische Perspektive</h1>
        <p>
          Jeder Mensch hat familiäre Wurzeln und lebt in aktuellen Familien- und
          Beziehungssystemen. Für mich als Berater bedeutet das, einerseits die
          jeweilige Einzelperson in ihrer konkreten Befindlichkeit und
          Sichtweise wahrzunehmen und zu verstehen und andererseits immer auch
          den biographischen familiären Hintergrund und die aktuellen
          Beziehungskonstellationen im Blick zu behalten.
        </p>
        <p>Die systemische Perspektive ermöglicht zweierlei Beratungsmodi:</p>
        <ol>
          <li>
            Die Arbeit mit Einzelpersonen bewirkt Veränderungen, die sich auf
            das soziale Mikrosystem auswirken. Wenn sich ein Element eines
            Systems verändert, können die anderen Elemente nicht so bleiben wie
            sie sind.
          </li>
          <li>
            Die Problem- und Konfliktaustragung in direkter Interaktion zwischen
            Partnern beziehungsweise Familienmitgliedern. Das kann die
            Paarbeziehung, die Elternbeziehung, die ganze Familie oder eine
            Subgruppierung betreffen. Schon manches schwierige Kind wurde
            handsam, nachdem die Eltern ihre Paarproblematik bearbeitet hatten.
            Speziell bei Eltern-Kind-Konflikten kann das vermittelnde
            Tätig-werden eines außenstehenden Beraters sehr hilfreich sein.
          </li>
        </ol>
      </div>
    </section>
  </Layout>
)

export default SystemicPage
